import { useCallback } from 'react';
import { isDevice } from '../../../../utils/device-handling/deviceDetectionUtils';
import { useNavigate } from '../../../../hooks/useNavigate';
import DialogView from '../../../../components/dialog-view/DialogView';
import styled from 'styled-components';
import { DialogContent } from '../../components/Styles';
import Receipt from '../../return-goods-dialog/Receipt';
import { WorkTaskStatus } from '../../../../api/api';
import NavigationHeader from '../../components/NavigationHeader';
import StatusTag from '../../../../components/status-tag/StatusTag';
import { getEnumDisplayValue } from '../../../../utils/enumUtils';
import LoadingOverlay from '../../../../components/loading-overlay/LoadingOverlay';
import { useSelector } from 'react-redux';
import { selectedTransferOrder } from '../../../../stateManagement/reducers/returnGoodsReducer';
import { GOODS_ROUTE } from '../../../../utils/constants';
import addQueryParams from '../../../../utils/routing/add-query-params';

interface Props {
  onClose?: () => void;
}

const ReturnReceiptView = (props: Props) => {
  const { onClose } = props;
  const order = useSelector(selectedTransferOrder);
  const fullscreenTask = isDevice();
  const navigate = useNavigate();

  const handleCloseDialog = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const handleCloseReceipt = useCallback(() => {
    navigate(addQueryParams('/' + GOODS_ROUTE, { id: '03' }));
  }, [navigate]);

  return (
    <>
      {!order ? (
        <LoadingOverlay />
      ) : (
        <>
          {fullscreenTask ? (
            <FullScreenContainer>
              <NavigationHeader
                headerTitle={'Returneringsbekræftelse'}
                onClose={handleCloseReceipt}
                subHeaderComponent={<StatusTag statusValue={order.transferStatus}>{order.transferStatus}</StatusTag>}
              />
              <Receipt returnOrder={order} />
            </FullScreenContainer>
          ) : (
            <DialogView handleClose={handleCloseDialog}>
              <NavigationHeader
                headerTitle={'Returneringsbekræftelse'}
                onClose={handleCloseDialog}
                subHeaderComponent={
                  <StatusTag workTaskStatus={order.transferStatus as WorkTaskStatus}>
                    {getEnumDisplayValue(order.transferStatus as WorkTaskStatus)}
                  </StatusTag>
                }
              />
              <StyledDialogContent>
                <Receipt returnOrder={order} />
              </StyledDialogContent>
            </DialogView>
          )}
        </>
      )}
    </>
  );
};

const FullScreenContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.palette.background.primary};
`;

const StyledDialogContent = styled(DialogContent)`
  width: 70vw;
  height: 70vh;
  padding: 0;
  background-color: ${(props) => props.theme.palette.background.primary};
`;

export default ReturnReceiptView;
