import { useMemo } from 'react';
import { Column } from 'react-table';

import useTableInstance from '../../../../../hooks/useTableInstance';
import { GoodsData } from '../../../../../models/Goods';
import { OrderItemDTO } from '../../../../../api/api';

export const useReceiptList = (goods: OrderItemDTO[]) => {
  const data = useMemo((): GoodsData[] => {
    if (!goods.length) return [];

    return goods.map((good) => {
      return {
        goodsId: good.itemId,
        goodsName: good.name,
        quantity: good.qty ?? 0
      } as GoodsData;
    });
  }, [goods]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Varenummers',
        accessor: 'goodsId',
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 200
      },
      {
        Header: 'Varenavn',
        accessor: 'goodsName',
        disableFilters: true,
        disableSortBy: true,
        minWidth: 600,
        maxWidth: 1200
      },
      {
        Header: 'Antal',
        accessor: 'quantity',
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 200
      }
    ] as Column<GoodsData>[];
  }, []);

  const tableInstance = useTableInstance<GoodsData>(data, columns);

  return { tableInstance };
};
