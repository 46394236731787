import styled from 'styled-components';
import {
  TaskTypeSectionDTO,
  QuestionType,
  QuestionOptionDTO,
  SectionQuestionTextDTO,
  SectionQuestionDTO,
  TaskTypeSectionTextDTO
} from '../../../../../../api/api';
import { ConfirmResult, useConfirmationDialog } from '../../../../../../hooks/useConfirmationDialog';
import { DialogBody } from '../../../../../../stateManagement/reducers/confirmDialogReducer';
import { SectionHeader } from '../../../../../../styling/FormStyling';
import Question from './Question';
import Checkbox from '../../../../../../components/checkbox/Checkbox';
import { useEffect, useState } from 'react';
import { SettingsIcon } from '../../../../../../assets/icons/SettingsIcon';
import Typography from '../../../../../../components/typography/Typography';
import Button from '../../../../../../components/button/Button';
import Select, { MenuItem } from '../../../../../../components/select/Select';
import { ExtendedSectionQuestionTextDTO } from '../../../../../../models/Question';
import InformationBox from '../../../../../../components/information-box/InformationBox';
import { EditIcon } from '../../../../../../assets/icons/EditIcon';
import TextField from '../../../../../../components/text-field/TextField';
import { CheckmarkIcon } from '../../../../../../assets/icons/CheckmarkIcon';
import { CloseIcon } from '../../../../../../assets/icons/CloseIcon';

const navigateDialogBody: DialogBody = {
  headerText: 'Er du sikker?',
  bodyText: 'Er du sikker på at du vil slette denne sektion? Alle underligggende spørgsmål vil blive slettet.',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

const deleteSectionWithDependableQuestionsDialogBody: DialogBody = {
  headerText: 'Er du sikker?',
  bodyText: 'Sektionen indeholder spørgsmål som bruges af andre sektioner. Er du sikker på at du vil slette denne sektion?',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

interface CustomTaskTypeSectionDTO extends TaskTypeSectionDTO {
  index: number;
  section: TaskTypeSectionTextDTO;
  addQuestion: () => void;
  setQuestions: (questions?: SectionQuestionTextDTO[]) => void;
  deleteSection: () => void;
  swapSections: (sectionIndex1: number, sectionIndex2: number) => void;
  setSection: (section: TaskTypeSectionTextDTO) => void;
  otherCheckListQuestions?: ExtendedSectionQuestionTextDTO[];
  validateIfQuestinoIsDependableQuestion: (questionId: number) => number | undefined;
}

const Section = (props: CustomTaskTypeSectionDTO) => {
  const {
    section,
    index,
    addQuestion,
    setQuestions,
    deleteSection,
    swapSections,
    setSection,
    otherCheckListQuestions,
    validateIfQuestinoIsDependableQuestion
  } = props;

  const {
    name,
    questions,
    sectionId,
    isRepeatable,
    dependsOnQuestionSectionQuestionId,
    dependsOnQuestionOptionId,
    dependsOnQuestionOptionInvert
  } = section;

  const confirmOption = [{ id: -1, questionOptionText: 'Bekræftet' }];
  const sectionIsSaved = (section?.sectionId ?? 0).toFixed(0) === (section?.sectionId ?? 0).toString();

  const { getConfirmation } = useConfirmationDialog();
  const [showSectionConfig, setShowSectionConfig] = useState(false);
  const [repeatable, setRepeatable] = useState(isRepeatable);
  const [selectedDependentOnSectionQuestionId, setSelectedDependentOnSectionQuestionId] = useState<number | undefined>(
    dependsOnQuestionSectionQuestionId ?? undefined
  );
  const [showRule, setShowRule] = useState(selectedDependentOnSectionQuestionId !== undefined);

  const [negatedRules, setNegatedRules] = useState(dependsOnQuestionOptionInvert ?? false);
  const [dependentOnOptions, setDependentOnOptions] = useState<QuestionOptionDTO[] | undefined>(
    otherCheckListQuestions?.find((q) => q.sectionQuestionId === selectedDependentOnSectionQuestionId)?.options
  );

  const [selectedDependentOnOptionId, setSelectedDependentOnOptionId] = useState<number | undefined>(
    dependsOnQuestionOptionId
  );

  const [isEditingSectionName, setIsEditingSectionName] = useState(false);
  const [editedSectionName, setEditedSectionName] = useState(section.name);

  useEffect(() => {
    handleDefaultValueForConfirmQuestions();
  }, []);

  const handleDefaultValueForConfirmQuestions = () => {
    if (dependsOnQuestionSectionQuestionId) {
      const type = otherCheckListQuestions?.find(
        (q) => q.sectionQuestionId === dependsOnQuestionSectionQuestionId
      )?.questionType;

      if (type === QuestionType.Confirm) {
        setDependentOnOptions(confirmOption);
        setSelectedDependentOnOptionId(confirmOption[0].id);
      }
    }
  };

  const removeSectionHandler = async () => {
    if (!sectionId) return;

    const hasDependableQuestions = !!section?.questions?.some(
      (question) => question.sectionQuestionId && validateIfQuestinoIsDependableQuestion(question.sectionQuestionId)
    );

    let confirmation: ConfirmResult;
    if (hasDependableQuestions) {
      confirmation = await getConfirmation(deleteSectionWithDependableQuestionsDialogBody);
    } else {
      confirmation = await getConfirmation(navigateDialogBody);
    }

    if (confirmation === 'confirm') {
      deleteSection();
    }
  };

  const setRepeatedSectionHandler = (index: number, checked: boolean) => {
    setRepeatable(checked);
    setSection({ ...section, isRepeatable: checked } as TaskTypeSectionDTO);
  };

  const removeQuestion = (questionId: number) => {
    if (!questions) return;
    setQuestions(questions.filter((q) => q.questionId !== questionId));
  };

  const setSectionDependedOnHandler = (index: number, dependentOn: number | undefined) => {
    setSelectedDependentOnSectionQuestionId(dependentOn);
    let invert = false;
    let options = undefined;
    let selectedOption = undefined;

    if (dependentOn) {
      const type = otherCheckListQuestions?.find((q) => q.sectionQuestionId === dependentOn)?.questionType;
      invert = negatedRules;

      if (type === QuestionType.SingleChoice) {
        options = otherCheckListQuestions?.find((q) => q.sectionQuestionId === dependentOn)?.options;
      } else if (type === QuestionType.Confirm) {
        options = confirmOption;
        selectedOption = confirmOption[0].id;
      }
    }

    setNegatedRules(invert);
    setDependentOnOptions(options);
    setSelectedDependentOnOptionId(selectedOption);

    setSection({
      ...section,
      dependsOnQuestionSectionQuestionId: dependentOn,
      dependsOnQuestionOptionInvert: invert,
      dependsOnQuestionOptionId: undefined
    } as TaskTypeSectionDTO);
  };

  const handleSelectedDependentOnOption = (optionId: number) => {
    setSelectedDependentOnOptionId(optionId);

    setSection({
      ...section,
      dependsOnQuestionOptionId: optionId
    } as TaskTypeSectionDTO);
  };

  const handleNegateRule = (negate: boolean) => {
    setNegatedRules(negate);

    setSection({
      ...section,
      dependsOnQuestionOptionInvert: negate
    } as TaskTypeSectionDTO);
  };

  const handleRule = (index: number, _showRule: boolean) => {
    setShowRule(_showRule);

    if (!_showRule) {
      setSectionDependedOnHandler(index, undefined);
    }
  };

  const swapQuestions = (index1: number, index2: number) => {
    if (!questions || index1 === questions.length || index2 === questions.length || index1 === -1 || index2 === -1) return;
    const array = [...questions];
    const temp = array[index1];
    array[index1] = array[index2];
    array[index2] = temp;
    setQuestions(array);
  };

  const updateQuestion = (questionId: number | undefined, field: keyof SectionQuestionDTO, checked: boolean) => {
    if (!questionId) return;
    const updatedQuestion = questions?.map((q) =>
      q.questionId === questionId
        ? {
            ...q,
            [field]: checked
          }
        : q
    );
    setQuestions(updatedQuestion);
  };

  const confirmEdit = () => {
    setSection({ ...section, name: editedSectionName });
    setIsEditingSectionName(false);
  };

  const cancelEdit = () => {
    setEditedSectionName(section.name);
    setIsEditingSectionName(false);
  };

  return (
    <StyledSection>
      <Row>
        {!isEditingSectionName && (
          <RowLeft>
            <StyledSectionHeader>
              {name} [{sectionId}]
              <Clickable onClick={() => setIsEditingSectionName(true)}>
                <EditIcon />
              </Clickable>
            </StyledSectionHeader>
          </RowLeft>
        )}
        {isEditingSectionName && (
          <RowLeft>
            <StyledSectionHeader>
              <TextField
                fullWidth
                value={editedSectionName}
                label="Navn"
                onChange={(e) => setEditedSectionName(e.target.value)}
              />
              <Clickable onClick={confirmEdit}>
                <CheckmarkIcon />
              </Clickable>
              <Clickable onClick={cancelEdit}>
                <CloseIcon />
              </Clickable>
            </StyledSectionHeader>
          </RowLeft>
        )}

        <RowRight>
          {repeatable && <InformationBox text="Gentagbar" />}
          {selectedDependentOnSectionQuestionId && <InformationBox text="Vises kun hvis..." />}
          <Clickable onClick={() => setShowSectionConfig(!showSectionConfig)}>
            <SettingsIcon size="24px" />
          </Clickable>
        </RowRight>
      </Row>

      {showSectionConfig && (
        <StyledSectionConfig>
          <Typography variant="h5" fontWeight="bold">
            Indstillinger
          </Typography>
          <Content>
            <Checkbox
              disabled={showRule}
              label={'Sektion gentager sig'}
              checked={repeatable}
              onChange={(e) => setRepeatedSectionHandler(index, e.target.checked)}
            />
          </Content>
          <Content>
            <Typography variant="h6" fontWeight="bold">
              Vis kun sektion hvis:
            </Typography>
            {showRule && (
              <>
                <Select
                  fullWidth
                  value={selectedDependentOnSectionQuestionId}
                  defaultValue={selectedDependentOnSectionQuestionId}
                  onChange={(e) => setSectionDependedOnHandler(index, e.target.value as number)}
                >
                  {otherCheckListQuestions &&
                    otherCheckListQuestions
                      .filter((q) => {
                        return q.questionType === QuestionType.Confirm || q.questionType === QuestionType.SingleChoice;
                      })
                      .map((q, i) => {
                        return (
                          <MenuItem key={q.sectionQuestionId} value={q.sectionQuestionId}>
                            [{q.sectionId}] id-{q.questionId}: {q.text} (
                            {q.questionType === QuestionType.Confirm && 'Bekræft'}
                            {q.questionType === QuestionType.SingleChoice && 'Valgmuligheder'})
                          </MenuItem>
                        );
                      })}
                </Select>
                <div>
                  <StyledSmallSelect
                    value={negatedRules}
                    defaultValue={negatedRules}
                    onChange={(e) => handleNegateRule(e.target.value as boolean)}
                  >
                    <MenuItem key={1} value={false as any}>
                      Er
                    </MenuItem>
                    <MenuItem key={0} value={true as any}>
                      Ikke er
                    </MenuItem>
                  </StyledSmallSelect>
                  <Select
                    fullWidth
                    value={selectedDependentOnOptionId}
                    defaultValue={selectedDependentOnOptionId}
                    onChange={(e) => handleSelectedDependentOnOption(e.target.value as number)}
                  >
                    {dependentOnOptions?.map((o, i) => {
                      return (
                        <MenuItem key={i} value={o.id}>
                          {o.questionOptionText}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </>
            )}
            {!sectionIsSaved && (
              <Typography variant="p" color="error">
                Gem tjeklisten, for at tilføje regler til sektionen
              </Typography>
            )}
            <Row>
              <Button
                variant="secondary"
                compact
                onClick={() => handleRule(index, !showRule)}
                disabled={repeatable || !sectionIsSaved}
              >
                {showRule ? 'Fjern' : 'Tilføj'} regl
              </Button>
            </Row>
          </Content>
          <Content>
            <Row>
              <RowLeft>
                <Button variant="secondary" compact onClick={() => swapSections(index, index - 1)}>
                  Ryk op
                </Button>
                <Button variant="secondary" compact onClick={() => swapSections(index + 1, index)}>
                  Ryk ned
                </Button>
                <Button variant="secondary" compact onClick={addQuestion}>
                  Tilføj spørgsmål
                </Button>
              </RowLeft>
              <RowRight>
                <Button variant="secondary" compact onClick={removeSectionHandler}>
                  Fjern sektion
                </Button>
              </RowRight>
            </Row>
          </Content>
        </StyledSectionConfig>
      )}
      {questions?.map((question, index) => (
        <Question
          key={question.questionId}
          question={question}
          sectionId={sectionId}
          index={index}
          removeQuestion={(questionId: number) => removeQuestion(questionId)}
          swapQuestions={(index1, index2) => swapQuestions(index1, index2)}
          updateQuestions={(field, checked) => updateQuestion(question.questionId, field, checked)}
          validateIfQuestinoIsDependableQuestion={validateIfQuestinoIsDependableQuestion}
        />
      ))}
    </StyledSection>
  );
};

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;

  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  border-color: ${(props) => props.theme.palette.grey.black10};

  margin: ${(props) => props.theme.spacing(3)} 0;
  padding: ${(props) => props.theme.spacing(3)};
`;

const Content = styled.div`
  margin-top: ${(props) => props.theme.spacing(6)};
  padding-bottom: ${(props) => props.theme.spacing(6)};
  border-bottom: 1px solid #e3e3e3;

  &:last-child {
    border-bottom: 0px;
  }
`;

const StyledSectionHeader = styled(SectionHeader)`
  height: ${(props) => props.theme.spacing(5)};
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const StyledSectionConfig = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing(8)};
  padding: ${(props) => props.theme.spacing(6)} ${(props) => props.theme.spacing(6)} 0 ${(props) => props.theme.spacing(6)};
  background-color: ${(props) => props.theme.palette.background.tertiary};
`;

const Row = styled.div`
  margin-top: ${(props) => props.theme.spacing(4)};
  display: flex;
  justify-content: space-between;
  &:first-child {
    margin-top: 0;
  }
`;

const StyledSmallSelect = styled(Select)`
  min-width: 120px;
  margin: ${(props) => props.theme.spacing(3)} 0;
`;

const RowLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: ${(props) => props.theme.spacing(6)};
`;

const RowRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: ${(props) => props.theme.spacing(6)};
`;

const Clickable = styled.div`
  cursor: pointer;
  display: inline-block;
  padding-left: ${(props) => props.theme.spacing(4)};
`;

export default Section;
