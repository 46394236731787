import styled from 'styled-components';
import MuiAutoComplete, { AutocompleteProps } from '@mui/material/Autocomplete';

type Props<T> = AutocompleteProps<T, undefined, boolean, undefined> & {
  compact?: boolean;
  multipleSelect?: boolean;
};

const AutoComplete = <T extends unknown>(props: Props<T>) => {
  return (
    <StyledMuiAutoComplete
      {...props}
      multiple={props.multipleSelect ?? false}
      loadingText={props.loadingText ?? 'Indlæser...'}
      noOptionsText={props.noOptionsText ?? 'Ingen valgmuligheder'}
    />
  );
};

const StyledMuiAutoComplete = styled((props) => <MuiAutoComplete {...props} />)`
  && .MuiOutlinedInput-root {
    padding: 0;

    .MuiAutocomplete-input {
      line-height: 1rem;
      padding: 12px 16px;
    }
  }
`;

export default AutoComplete;
