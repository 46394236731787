import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { OrderDTO, OrderStatus, WarehouseDTO } from '../../../../api/api';
import Table from '../../../../blocks/table/TableClient';
import { HorizontalTabProps } from '../../../../components/tabs-horizontal/TabsHorizontal';
import GoodsService from '../../../../services/GoodsService';
import NotificationService from '../../../../services/NotificationService';
import { log } from '../../../../utils/logging/log';
import AutoComplete from '../../../../components/auto-complete/AutoComplete';
import TextField from '../../../../components/text-field/TextField';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserProfile } from '../../../../stateManagement/reducers/userProfileReducer';
import { setSelectedOrderReceipt } from '../../../../stateManagement/reducers/orderGoodsReducer';
import useOrderTable from './useOrderTable';

interface Props extends HorizontalTabProps {
  handleOpenOrderReceipt: () => void;
}

const OrdersTab = (props: Props) => {
  const { handleOpenOrderReceipt } = props;
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [loadingWarehouses, setLoadingWarehouses] = useState(false);
  const [orders, setOrders] = useState<OrderDTO[]>([]);
  const [warehouses, setWarehouses] = useState<WarehouseDTO[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseDTO | null>();
  const warehouseId = useSelector(selectUserProfile).userProfile.vehicle?.vehicleId;
  const dispatch = useDispatch();

  const getWarehouses = useCallback(() => {
    setLoadingWarehouses(true);
    GoodsService.getWarehouses()
      .then((_warehouses) => {
        const filteredWarehouses = _warehouses.filter((w) => w.warehouseId?.startsWith('V-'));
        filteredWarehouses && setWarehouses(filteredWarehouses);
        const myWarehouse = filteredWarehouses.find((warehouse) => warehouse.warehouseId === warehouseId);
        setSelectedWarehouse(myWarehouse);
      })
      .catch((e) => {
        log(e);
      })
      .finally(() => {
        setLoadingWarehouses(false);
      });
  }, [warehouseId]);

  const fetchTransferOrders = useCallback(() => {
    if (!warehouseId && !selectedWarehouse?.warehouseId) return;

    setLoadingOrders(true);
    GoodsService.getTransferOrdersByWarehouse(selectedWarehouse?.warehouseId ?? warehouseId ?? '')
      .then((res) => {
        setOrders(res);
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Kunne ikke hente bestillinger for ' + (selectedWarehouse?.warehouseId ?? warehouseId));
      })
      .finally(() => setLoadingOrders(false));
  }, [selectedWarehouse?.warehouseId, warehouseId]);

  useEffect(() => {
    fetchTransferOrders();
  }, [fetchTransferOrders]);

  useEffect(() => {
    getWarehouses();
  }, [getWarehouses]);

  const openOrderReceipt = useCallback(
    (order?: OrderDTO) => {
      if (!order) return;
      dispatch(setSelectedOrderReceipt(order));
      handleOpenOrderReceipt();
    },
    [dispatch, handleOpenOrderReceipt]
  );

  const moveToCar = useCallback((id: number) => {
    GoodsService.moveToVehicle(id)
      .then(() => {
        NotificationService.success('Bestilling flyttet til bil');
        setOrders((prevOrders) =>
          prevOrders.map((order) => (order.orderId === id ? { ...order, orderStatus: OrderStatus.Received } : order))
        );
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Kunne ikke flytte bestilling til bil');
      });
  }, []);

  const tableInstance = useOrderTable(orders, moveToCar, openOrderReceipt);

  return (
    <Container>
      <AutoComplete
        value={selectedWarehouse || null}
        onChange={(_, value) => setSelectedWarehouse(value)}
        options={warehouses}
        getOptionLabel={(value) => `${value.name} (${value.warehouseId})`}
        renderInput={(params) => <TextField {...params} label={'Lager'} />}
        loading={loadingWarehouses}
        noOptionsText={'Kunne ikke finde lager'}
        disableClearable={true}
        style={{ marginBottom: 10 }}
      />
      <Table loading={loadingOrders} tableInstance={tableInstance} alwaysShowSort noPadding showPagination></Table>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  .table-footer {
    justify-content: end;
  }
`;

export default OrdersTab;
