import { Section, SectionHeader } from '../../../../../styling/FormStyling';
import TextRow, { RowLabel, RowValue } from '../../components/TextRow';
import { StyledSectionContent, TaskDetailsDispatchContext } from '../TaskDetailsStep';
import { ColumnFlex2, ColumnFlex3 } from '../../../../../styling/Styling';
import AddressAutocomplete from '../../../../../components/address-autocomplete/AddressAutocomplete';
import { WorkTaskDTOExtended } from '../../../../../models/TaskType';
import { getLocationString } from '../../../../../utils/location/locationHandling';
import { getEnumDisplayValue } from '../../../../../utils/enumUtils';
import { getCreatedByText } from '../../../../../utils/stringHandling';
import { LocationDTO, WorkTaskStatus } from '../../../../../api/api';
import styled from 'styled-components';
import MapComponent from '../../../../../components/map-component/MapComponent';
import BasicPopover from '../../../../../components/popover/Popover';
import Person from '../../../../../components/person/Person';
import ContactCard from '../../../../../components/contact-card/ContactCard';
import { useContext } from 'react';

interface Props {
  editable?: boolean;
  task?: WorkTaskDTOExtended;
  toggleFieldIsDirty: (key: string, value: boolean) => void;
}

export const Information = (props: Props) => {
  const { editable, task, toggleFieldIsDirty } = props;
  const dispatchTaskDetails = useContext(TaskDetailsDispatchContext);

  const handleSelectAddress = (location?: LocationDTO) => {
    if (!location) return;

    dispatchTaskDetails({ type: 'set-task-location', payload: location });
    toggleFieldIsDirty('taskLocation', true);
  };

  return (
    <Section direction="row" noBorder>
      <ColumnFlex3>
        <SectionHeader>Information</SectionHeader>
        <StyledSectionContent>
          <ColumnFlex3>
            {editable ? (
              <AddressAutocomplete onSelect={handleSelectAddress} label={'Adresse'} prevLocation={task?.taskLocation} />
            ) : (
              <TextRow
                inputMode={editable}
                disabled
                label="Adresse"
                value={task?.taskLocation ? getLocationString(task?.taskLocation) : '-'}
              />
            )}
            <TextRow
              inputMode={editable}
              label="Bemærkninger til planlægning"
              value={task?.notesForPlanning ?? '-'}
              onBlur={(text: string) => dispatchTaskDetails({ type: 'set-notes-for-planning', payload: text })}
              isDirty={toggleFieldIsDirty}
              disabled={task?.status !== WorkTaskStatus.Created && task?.status !== WorkTaskStatus.Pause}
            />
            <TextRow inputMode={false} label="Note til tekniker" value={task?.notesForTechnician ?? '-'} disabled />
            <TextRow
              inputMode={editable}
              disabled
              label={task?.assetType ? getEnumDisplayValue(task?.assetType) : 'Asset'}
              value={task?.assetId ?? '-'}
            />
            {editable ? (
              <>
                <TextRow
                  inputMode={editable}
                  disabled
                  label="Oprettet af"
                  value={getCreatedByText(task?.createdByName, task?.createdByEmail)}
                />
                <TextRow inputMode disabled label="Tildelt tekniker" value={task?.assignedToName ?? '-'} />
              </>
            ) : (
              <>
                <StyledRowBase>
                  <RowLabel>Oprettet af</RowLabel>
                  <RowValue>
                    {task?.createdByName && (
                      <BasicPopover
                        buttonElement={<Person name={task.createdByName} />}
                        popoverElement={<ContactCard email={task.createdByEmail} />}
                      />
                    )}
                  </RowValue>
                </StyledRowBase>
                <StyledRowBase>
                  <RowLabel>Tildelt tekniker</RowLabel>
                  <RowValue>
                    {task?.assignedToName && (
                      <BasicPopover
                        buttonElement={<Person name={task.assignedToName} />}
                        popoverElement={<ContactCard email={task.assignedToEmail} />}
                      />
                    )}
                  </RowValue>
                </StyledRowBase>
              </>
            )}
            {task?.reasonCode && (
              <TextRow inputMode={editable} disabled label="Årsagsforklaring" value={task?.reasonCode.reasonText ?? '-'} />
            )}
            {task?.statusTransactionMessage && (
              <TextRow
                inputMode={editable}
                disabled
                label="Uddybende årsagsforklaring"
                value={task?.statusTransactionMessage}
              />
            )}
          </ColumnFlex3>
        </StyledSectionContent>
      </ColumnFlex3>
      <OuterMapContainer>
        <InnerMapContainer>
          <MapComponent
            markers={
              !!task?.taskLocation?.latitude && !!task?.taskLocation?.longitude
                ? [
                    {
                      markerType: 'Main',
                      lat: task?.taskLocation?.latitude,
                      lng: task?.taskLocation?.longitude,
                      key: 'TaskDetailsStepDetails'
                    }
                  ]
                : []
            }
            options={{
              keyboardShortcuts: false,
              mapTypeControl: false,
              fullscreenControl: false,
              zoomControl: false
            }}
          />
        </InnerMapContainer>
      </OuterMapContainer>
    </Section>
  );
};

const InnerMapContainer = styled.div`
  margin-left: 12px;
  height: 250px;
`;

const OuterMapContainer = styled(ColumnFlex2)`
  justify-content: center;
`;

const StyledRowBase = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
`;
