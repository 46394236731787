import styled from 'styled-components';
import Typography from '../../../components/typography/Typography';
import { DeleteIcon } from '../../../assets/icons/DeleteIcon';
import Button from '../../../components/button/Button';
import { formatTimeAndDateString } from '../../../utils/dateHandling';
import { SelectedGoods } from '../../../models/Goods';
import { Table, TableHead, TableRow, TableBody, TableCell } from '../../../components/table/Table';
import StatusIndicator, { STATUS } from '../../../components/status-indicator/StatusIndicator';
import QuantityCell from './QuantityCell';
import TextField from '../../../components/text-field/TextField';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectGoodsLastUpdated } from '../../../stateManagement/reducers/countGoodsReducer';
interface Props {
  reset?: () => void;
  goods: SelectedGoods[];
  handleCheckGood: (id: string, checked: boolean) => void;
  updateGoodQuantity: (id: string, value: number) => void;
}

const CountGoodsList = (props: Props) => {
  const { goods, reset, handleCheckGood, updateGoodQuantity } = props;

  const [searchText, setSearchText] = useState('');

  const lastUpdated = useSelector(selectGoodsLastUpdated);

  const filteredGoods = useMemo(() => {
    if (!searchText) return goods;
    return goods.filter((g) => g.name?.toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase()));
  }, [goods, searchText]);

  return (
    <>
      <Header>
        <TextField compact value={searchText} onChange={(e) => setSearchText(e.target.value)} label="Søg blandt varer" />
        <Row>
          {lastUpdated && (
            <>
              <Typography color="greyedOut">
                Sidst opdateret: {formatTimeAndDateString(lastUpdated.toISOString())}
              </Typography>
              <Button variant="secondary" onClick={reset}>
                <DeleteIcon /> Nulstil optælling
              </Button>
            </>
          )}
        </Row>
      </Header>

      <StyledTableContainer>
        <Table cellSpacing={'1px'}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Varenavn</TableCell>
              <TableCell>Antal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredGoods.map((good) => (
              <TableRow key={good.itemId}>
                <TableCell>
                  {good.checked !== undefined && (
                    <StatusIndicator
                      interactive
                      status={good.checked ? STATUS.GREEN : STATUS.GREY}
                      onClick={() => handleCheckGood(good.itemId ?? '', !good.checked)}
                    />
                  )}
                </TableCell>
                <TableCell>{good.name}</TableCell>
                <TableCell>
                  <QuantityCell
                    row={{ goodsId: good.itemId }}
                    minValue={0}
                    qty={good.qty?.toString()}
                    setGoodsAmount={(val, id) => updateGoodQuantity(id ?? '', val)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => props.theme.spacing(1)};
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.theme.spacing(3)};
  height: 50px;
`;

const StyledTableContainer = styled.div`
  height: 90%;
  overflow-y: scroll;
`;

export default CountGoodsList;
