import { useMemo } from 'react';
import styled from 'styled-components';

import Table from '../../../blocks/table/TableClient';
import Typography from '../../../components/typography/Typography';
import { SelectedGoods, DeliveryLocation } from '../../../models/Goods';
import { useSelectedGoodsList } from '../hooks/useSelectedGoodsList';
import { DeleteIcon } from '../../../assets/icons/DeleteIcon';
import { DialogBody } from '../../../stateManagement/reducers/confirmDialogReducer';
import Button from '../../../components/button/Button';
import { useConfirmationDialog } from '../../../hooks/useConfirmationDialog';
import AutoComplete from '../../../components/auto-complete/AutoComplete';
import TextField from '../../../components/text-field/TextField';

const clearGoodsDialog: DialogBody = {
  headerText: 'Vil du fjerne alle varer i din kurv?',
  bodyText: 'Du er ved at fjerne alle varer fra din kurv. Er du sikker på, at du vil fortsætte?',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

interface Props {
  selectedGoods: SelectedGoods[];
  setGoodsAmount: (value: number, goodsId?: string, goodsBatch?: string, goodsSerial?: string) => void;
  removeGoods?: (goodsId?: string, goodsBatch?: string, goodsSerial?: string) => void;
  className?: string;
  showAvailable?: boolean;
  showDeliverTo?: boolean;
  selectedDeliveryLocation?: DeliveryLocation;
  setDeliveryLocation?: (delivery: DeliveryLocation) => void;
  deliveryOptions?: DeliveryLocation[];
  clearSelectedGoods?: () => void;
  renderFullHeight?: boolean;
}

const SelectedGoodsList = (props: Props) => {
  const {
    selectedGoods,
    className,
    removeGoods,
    setGoodsAmount,
    showAvailable = true,
    showDeliverTo = false,
    selectedDeliveryLocation,
    setDeliveryLocation,
    deliveryOptions,
    clearSelectedGoods,
    renderFullHeight = false
  } = props;

  const { tableInstance } = useSelectedGoodsList(selectedGoods, setGoodsAmount, removeGoods, showAvailable);
  const { getConfirmation } = useConfirmationDialog();

  const handleClearSelectedGoods = () => {
    getConfirmation(clearGoodsDialog).then((res) => {
      if (res === 'confirm') {
        clearSelectedGoods && clearSelectedGoods();
      }
    });
  };

  const selectedCount = useMemo(() => {
    return selectedGoods.reduce((acc, goods) => acc + (goods?.qty ?? 1), 0);
  }, [selectedGoods]);

  return (
    <>
      <Header>
        {showDeliverTo && selectedDeliveryLocation && (
          <>
            <Typography variant="h5" fontWeight="bold">
              Varer sendes til:
            </Typography>
            {deliveryOptions && setDeliveryLocation ? (
              <AutocompleteContainer>
                <AutoComplete
                  onChange={(_, value, reason) => setDeliveryLocation(value as DeliveryLocation)}
                  options={deliveryOptions}
                  value={selectedDeliveryLocation}
                  renderInput={(params) => <TextField {...params} label={'Specifikt dropsted'} fullWidth />}
                  getOptionLabel={(option) => option.id && `(${option.id}) ${option.name}`}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  noOptionsText={'Kan ikke hente dropsteder'}
                  fullWidth
                  disableClearable
                />
              </AutocompleteContainer>
            ) : (
              <Typography variant="h5" fontWeight="bold">
                ({selectedDeliveryLocation.id}) {selectedDeliveryLocation.name}
              </Typography>
            )}
          </>
        )}
        {clearSelectedGoods && (
          <Button variant="secondary" onClick={handleClearSelectedGoods} disabled={selectedCount === 0}>
            <DeleteIcon size="20px" />
            Ryd kurv {selectedCount > 0 ? `(${selectedCount})` : ''}
          </Button>
        )}
      </Header>
      <StyledTable
        tableInstance={tableInstance}
        loading={false}
        alwaysShowSort
        noDataText="Ingen valgte varer. Vælg fra listen over mulige varer."
        className={className}
        renderFullHeight={renderFullHeight}
      />
    </>
  );
};

const StyledTable = styled(Table)`
  &.table-outer-container {
    margin: 0;
    padding: 0;
  }
  .table-content-container {
    margin: 0;
    padding: 0;
  }
`;

const AutocompleteContainer = styled.div`
  width: 400px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${(props) => props.theme.spacing(3)} 0;
`;

export default SelectedGoodsList;
