import Popover, { PopoverOrigin } from '@mui/material/Popover';
import { useState } from 'react';
import { isDevice } from '../../utils/device-handling/deviceDetectionUtils';

interface Props {
  buttonElement: JSX.Element;
  popoverElement: JSX.Element;
  activeByClick?: boolean;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  disablePopover?: boolean;
}

export default function BasicPopover(props: Props) {
  const {
    buttonElement,
    popoverElement,
    anchorOrigin = {
      vertical: 'top',
      horizontal: 'center'
    },
    transformOrigin = {
      vertical: 'bottom',
      horizontal: 'center'
    },
    disablePopover = false
  } = props;

  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);

  const handleSetActive = (anchor: Element) => {
    if (anchorEl === undefined) {
      setAnchorEl(anchor);
    } else {
      setAnchorEl(undefined);
    }
  };

  const activeByClick = isDevice() || props.activeByClick || false;

  return (
    <div>
      {activeByClick ? (
        <div
          style={{ cursor: disablePopover ? 'default' : 'pointer' }}
          onClick={(event) => handleSetActive(event.currentTarget)}
        >
          {buttonElement}
        </div>
      ) : (
        <div onMouseEnter={(event) => setAnchorEl(event.currentTarget)} onMouseLeave={() => setAnchorEl(undefined)}>
          {buttonElement}
        </div>
      )}

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: activeByClick ? 'auto' : 'none'
        }}
        open={!!anchorEl && !disablePopover}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {popoverElement}
      </Popover>
    </div>
  );
}
