import { BaseWorkTaskTypeEnum } from '../../../../api/api';
import { TASKS_DETAILS, TASKS_DETAILS_DIALOG_ROUTE } from '../../../../utils/constants';
import { isDevice } from '../../../../utils/device-handling/deviceDetectionUtils';
import addQueryParams from '../../../../utils/routing/add-query-params';

export const linkToTask = (id: string, type?: BaseWorkTaskTypeEnum) => {
  const worktaskType = type || BaseWorkTaskTypeEnum.WorkTask;
  return isDevice()
    ? '../' +
        addQueryParams(TASKS_DETAILS, {
          id: id,
          type: worktaskType
        })
    : addQueryParams(undefined, { id: id, type: worktaskType }, TASKS_DETAILS_DIALOG_ROUTE);
};
