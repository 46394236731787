import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { SelectedGoods, WorkTaskOrderInfo } from '../../models/Goods';
import { OrderDTO } from '../../api/api';

interface OrderGoodsState {
  goodsList: SelectedGoods[];
  deliveryLocation: {
    id: string;
    name: string;
  };
  deliveryDate: string;
  notes: string;
  selectedOrderReceipt?: OrderDTO;
  workTaskInfo?: WorkTaskOrderInfo;
}

const initialState: OrderGoodsState = {
  goodsList: [],
  deliveryLocation: { id: '', name: '' },
  deliveryDate: '',
  notes: '',
  selectedOrderReceipt: undefined,
  workTaskInfo: undefined
};

const orderGoodsSlice = createSlice({
  name: 'orderGoods',
  initialState: initialState,
  reducers: {
    setOrderGoodsList: (state: OrderGoodsState, action: PayloadAction<SelectedGoods[]>) => {
      state.goodsList = action.payload;
    },
    setSelectedDeliveryLocation: (state: OrderGoodsState, action: PayloadAction<{ id: string; name: string }>) => {
      state.deliveryLocation = action.payload;
    },
    setDeliveryDate: (state: OrderGoodsState, action: PayloadAction<string>) => {
      state.deliveryDate = action.payload;
    },
    setNotes: (state: OrderGoodsState, action: PayloadAction<string>) => {
      state.notes = action.payload;
    },
    setSelectedOrderReceipt: (state: OrderGoodsState, action: PayloadAction<OrderDTO | undefined>) => {
      state.selectedOrderReceipt = action.payload;
    },
    setWorkTaskOrderInfo: (state: OrderGoodsState, action: PayloadAction<WorkTaskOrderInfo>) => {
      state.workTaskInfo = action.payload;
    }
  }
});

export const {
  setOrderGoodsList,
  setSelectedDeliveryLocation,
  setDeliveryDate,
  setNotes,
  setSelectedOrderReceipt,
  setWorkTaskOrderInfo
} = orderGoodsSlice.actions;
export const selectOrderGoodsList = (state: RootState) => state.orderGoodsReducer.goodsList;
export const selectOrderGoodsDeliveryLocation = (state: RootState) => state.orderGoodsReducer.deliveryLocation;
export const selectOrderGoodsDeliveryDate = (state: RootState) => state.orderGoodsReducer.deliveryDate;
export const selectOrderGoodsNotes = (state: RootState) => state.orderGoodsReducer.notes;
export const selectSelectedOrderReceipt = (state: RootState) => state.orderGoodsReducer.selectedOrderReceipt;
export const selectWorkTaskOrderInfo = (state: RootState) => state.orderGoodsReducer.workTaskInfo;

export default orderGoodsSlice.reducer;
