import { getUserEmail } from '../authProvider/authProvider';

// environmentVariable eg. window._env_.ORDER_GOODS_TO_TASK_ENABLED
export const userAllowedThroughFeatureFlag = (environmentVariable: string) => {
  if (environmentVariable === 'false') {
    return false;
  } else if (environmentVariable === 'true') {
    return true;
  } else if (environmentVariable?.includes('@')) {
    const allowedRoles = environmentVariable.split(',');
    if (allowedRoles.find((user) => user.trim().toLocaleLowerCase() === getUserEmail().toLocaleLowerCase())) {
      return true;
    }
  }
  return false;
};
