import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import { OrderDTO, OrderStatus } from '../../../../api/api';
import useTableInstance from '../../../../hooks/useTableInstance';
import { formatDateString, formatTimeAndDateString, sortDateTime } from '../../../../utils/dateHandling';
import { getInitialsFromEmail } from '../../../../utils/initialsUtils';
import { getEnumDisplayValue } from '../../../../utils/enumUtils';
import StatusTag from '../../../../components/status-tag/StatusTag';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';

interface OrderData {
  status?: OrderStatus;
  transferId?: number;
  toWarehouseId?: number;
  create?: string;
  additionalReceiptDate?: string;
  description?: string;
  quantity?: number;
}

const useWorkTaskOrdersTable = (orders: OrderDTO[], openOrderReceipt: (order?: OrderDTO) => void) => {
  const data = useMemo(() => {
    if (!orders.length) return [];
    return orders.map((order) => {
      return {
        status: order.orderStatus,
        transferId: order.transferId,
        toWarehouseId: order.toWarehouseId,
        created:
          formatTimeAndDateString(order.created ?? '') + ' (' + getInitialsFromEmail(order.createdByEmail ?? '') + ')',
        additionalReceiptDate: formatDateString(order.additionalReceiptDate ?? ''),
        description: order.description,
        quantity: order.items?.length
      } as OrderData;
    });
  }, [orders]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Staus',
        accessor: 'status',
        width: 120,
        minWidth: 120,
        disableFilters: true,
        Cell: (cellProps: CellProps<OrderData>) => {
          const { cell } = cellProps;
          return <StatusTag orderStatus={cell.value}>{getEnumDisplayValue(cell.value as OrderStatus)}</StatusTag>;
        }
      },
      {
        Header: 'Leveringsdato',
        accessor: 'additionalReceiptDate',
        width: 120,
        minWidth: 120,
        disableFilters: true,
        sortType: (a, b) => sortDateTime(a.values.lastUpdated, b.values.lastUpdated)
      },
      {
        Header: 'Medarbejder',
        accessor: 'toWarehouseId',
        width: 120,
        minWidth: 120,
        disableFilters: true
      },
      {
        Header: 'Antal',
        accessor: 'quantity',
        width: 120,
        minWidth: 120,
        disableFilters: true
      },
      {
        Header: '',
        accessor: 'actions',
        width: 200,
        minWidth: 200,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }: CellProps<OrderData>) => (
          <ButtonsContainer>
            <Button
              variant="secondary"
              onClick={() => openOrderReceipt(orders.find((order) => order.transferId === row.original.transferId))}
            >
              Se bestilling
            </Button>
          </ButtonsContainer>
        )
      }
    ] as Column<OrderData>[];
  }, [orders]);

  return useTableInstance(data, columns);
};

export default useWorkTaskOrdersTable;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: right;
  column-gap: ${(props) => props.theme.spacing(2)};
  margin-right: ${(props) => props.theme.spacing(10)};
`;
