import { ContactPersonTypeEnum, WorkOrderInfoDto } from '../../../../../api/api';
import { Section, SectionContent, SectionHeader } from '../../../../../styling/FormStyling';
import { ColumnFlex3 } from '../../../../../styling/Styling';
import { formatTimeAndDateString } from '../../../../../utils/dateHandling';
import { getEnumDisplayValue } from '../../../../../utils/enumUtils';
import TextRow from '../../components/TextRow';
import { StyledSectionContent } from '../TaskDetailsStep';

interface Props {
  meterInformation: WorkOrderInfoDto;
}
export const MeterInformation = (props: Props) => {
  const { meterInformation } = props;
  const info = meterInformation.workOrderInfo;

  return (
    <>
      <Section>
        <SectionHeader>Måler information</SectionHeader>
        <SectionContent>
          <ColumnFlex3>
            <h4>Overordnet info</h4>
            <TextRow label="Beskrivelse" value={info?.description ?? ''} />
            <TextRow label="Startdato" value={`${formatTimeAndDateString(info?.planningInfo?.taskStart ?? '')}  `} />
            <TextRow label="Slutdato" value={`${formatTimeAndDateString(info?.planningInfo?.taskEnd ?? '')} `} />
            <TextRow label="Planlagt til" value={`${formatTimeAndDateString(info?.planningInfo?.plannedArrival ?? '')}  `} />
            <TextRow label="Tid afsat" value={info?.planningInfo?.plannedDuration + ' minutter?'} />
            <TextRow
              label="Ønsket tekniker"
              value={`${info?.planningInfo?.assignedTechnicianName} (${info?.planningInfo?.assignedTechnicianEmail})`}
            />
            <TextRow label="Målernummer" value={info?.meterId ?? ''} />
            <TextRow label="Målerrammenummer" value={info?.meterFrameId ?? ''} />
            <TextRow label="Forbindelsespunkt" value={info?.connectionPointId ?? ''} />
            <TextRow label="WTM ID? (WorkOrderId)" value={info?.workOrderId ?? ''} />
            <TextRow label="WTM ID? (WorkOrderNumber)" value={info?.workOrderNumber ?? ''} />
            <TextRow label="Beskrivelses type kode" value={info?.workDescriptionTypeCode ?? ''} />
            <TextRow label="Fakturerbar" value={info?.billable ? 'Ja' : 'Nej'} />
            <TextRow label="Projektnummer" value={info?.projectNumber ?? ''} />
            <TextRow label="Adresse" value={info?.address?.addressAsText ?? ''} />
            <TextRow
              label=""
              value={`${info?.address?.streetName} ${info?.address?.houseNumber} ${info?.address?.floor} ${info?.address?.door}, ${info?.address?.postalCode} ${info?.address?.cityName} (${info?.address?.localName})`}
            />
          </ColumnFlex3>
        </SectionContent>
        <br />
        <SectionContent>
          <ColumnFlex3>
            <h4>Adgangsforhold</h4>
            <TextRow label="Målerrammens placering" value={info?.meterPlacement ?? ''} />
            <TextRow label="Kontaktperson" value={info?.accessInformation?.contactPerson ?? ''} />
            <TextRow label="Dørkode" value={info?.accessInformation?.doorCode ?? ''} />
            <TextRow label="Dørkode kommentar" value={info?.accessInformation?.doorCodeComment ?? ''} />
            <TextRow label="Nøglenummer" value={info?.accessInformation?.keyNumber ?? ''} />
            <TextRow label="Nøglekommentar" value={info?.accessInformation?.keyComment ?? ''} />
            <TextRow label="Nøgleplacering" value={info?.accessInformation?.keyPlacement ?? ''} />
            <TextRow label="Nøgle opbevaringsplavering" value={info?.accessInformation?.keyStorageLocation ?? ''} />
          </ColumnFlex3>
        </SectionContent>
        <br />
        <br />
        <SectionContent>
          {info?.contacts?.map((contact) => (
            <>
              <h4>
                Kontaktinformation: {getEnumDisplayValue(contact.contactType ?? ContactPersonTypeEnum.AlternativeContact)}
              </h4>
              <StyledSectionContent>
                <ColumnFlex3>
                  <TextRow label="Navn" value={contact.name ?? ''} />
                  <TextRow label="Telefonnummer" value={contact.phoneNumber ?? ''} />
                  <TextRow label="Email" value={contact.email ?? ''} />
                </ColumnFlex3>
                <ColumnFlex3>
                  <TextRow label="Firma" value={contact.companyName ?? ''} />
                  <TextRow
                    label="Adresse"
                    value={
                      contact?.address?.addressAsText ??
                      `${contact?.address?.streetName} ${contact?.address?.houseNumber} ${contact?.address?.floor} ${contact?.address?.door}, ${contact?.address?.postalCode} ${contact?.address?.cityName} (${contact?.address?.localName})`
                    }
                  />

                  <TextRow label="Detaljer" value={contact?.details ?? ''} />
                </ColumnFlex3>
              </StyledSectionContent>
            </>
          ))}
        </SectionContent>
      </Section>
    </>
  );
};
