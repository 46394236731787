import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Select from '../../../../../components/select/Select';
import { SectionProps } from '../../../../../models/CommonProps/SectionProps';
import { TaskCreationTask } from '../../../../../models/TaskCreationTask';
import { updateTask } from '../../../../../stateManagement/reducers/taskCreationReducer';
import { SectionContent, Row } from '../../../../../styling/FormStyling';
import { AlarmTypeDTO, AlarmTypeEnum } from '../../../../../api/api';
import TaskService from '../../../../../services/TaskService';
import NotificationService from '../../../../../services/NotificationService';
import { log } from '../../../../../utils/logging/log';
import { getEnumDisplayValue } from '../../../../../utils/enumUtils';
import styled from 'styled-components';

interface Props extends SectionProps {
  task: TaskCreationTask;
}

const AlarmTypeSection = (props: Props) => {
  const { task } = props;
  const [alarmTypes, setAlarmTypes] = useState<AlarmTypeDTO[]>([]);
  const [selectedAlarmType, setSelectedAlarmType] = useState<AlarmTypeEnum | undefined>(task.alarmType?.alarmType);
  const dispatch = useDispatch();

  useEffect(() => {
    if (alarmTypes.length < 1) {
      TaskService.getAlarmTypes()
        .then((res) => {
          setAlarmTypes(res);
        })
        .catch((error) => {
          log(error);
          NotificationService.error('Kunne ikke indlæse alarmtyper');
        });
    }
  }, [alarmTypes.length, selectedAlarmType]);

  const handleSetAlarmtype = (type?: AlarmTypeEnum) => {
    setSelectedAlarmType(type);
    const alarmType = alarmTypes?.find((a) => a.alarmType === type);
    dispatch(
      updateTask({
        id: task.taskCreationId ?? '',
        task: {
          deadline: alarmType?.slaDate ?? '',
          alarmType: alarmType
        }
      })
    );
  };

  return (
    <SectionContent>
      <Row>
        <RowItemContainer>
          <Select
            label="Alarmtype"
            value={selectedAlarmType}
            required
            onChange={(e) => handleSetAlarmtype(e.target.value as AlarmTypeEnum)}
            error={task?.baseErrors?.alarmType && !selectedAlarmType}
            fullWidth
          >
            {alarmTypes?.map((alarmType) => (
              <MenuItem key={alarmType.alarmType} value={alarmType.alarmType}>
                {alarmType?.alarmType && getEnumDisplayValue(alarmType.alarmType)}
              </MenuItem>
            ))}
          </Select>
        </RowItemContainer>
        <RowItemContainer />
      </Row>
    </SectionContent>
  );
};

export default AlarmTypeSection;

const RowItemContainer = styled.div`
  flex: 1 1 0;
  width: 0;
  display: flex;
  align-items: center;
`;
