import React from 'react';
import styled, { css } from 'styled-components';

import { TypographyVariant, Typography as TypographyType } from '../../theme/light-theme';

const h1Styles = css`
  ${(props) => ({ ...props.theme.typography.h1 })};
`;

const h2Styles = css`
  ${(props) => ({ ...props.theme.typography.h2 })};
`;

const h3Styles = css`
  ${(props) => ({ ...props.theme.typography.h3 })};
`;

const h4Styles = css`
  ${(props) => ({ ...props.theme.typography.h4 })}
`;

const h5Styles = css`
  ${(props) => ({ ...props.theme.typography.h5 })}
`;

const h6Styles = css`
  ${(props) => ({ ...props.theme.typography.h6 })}
`;

const pStyles = css`
  ${(props) => ({ ...props.theme.typography.p })}
`;

const spanStyles = css`
  ${(props) => ({ ...props.theme.typography.span })};
`;

const bStyles = css`
  ${(props) => ({ ...props.theme.typography.b })};
`;

const noWrapStyles = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const stylesWrapper: TypographyType = {
  h1: h1Styles,
  h2: h2Styles,
  h3: h3Styles,
  h4: h4Styles,
  h5: h5Styles,
  h6: h6Styles,
  p: pStyles,
  span: spanStyles,
  b: bStyles
};

const colorMapper = {
  initial: css`
    color: initial;
  `,
  inherit: css`
    color: inherit;
  `,
  primary: css`
    color: ${({ theme }) => theme.palette.main.yellow.primary};
  `,
  textPrimary: css`
    color: ${({ theme }) => theme.palette.text.primary};
  `,
  textMenu: css`
    color: ${({ theme }) => theme.palette.grey.black40};
  `,
  textSecondary: css`
    color: ${({ theme }) => theme.palette.text.secondary};
  `,
  textTertiary: css`
    color: ${({ theme }) => theme.palette.text.tertiary};
  `,
  greyedOut: css`
    color: ${({ theme }) => theme.palette.grey.black20};
  `,
  error: css`
    color: ${({ theme }) => theme.palette.functions.error.primary};
  `,
  warning: css`
    color: ${({ theme }) => theme.palette.functions.warning.primary};
  `,
  success: css`
    color: ${({ theme }) => theme.palette.functions.success.primary};
  `
};

const variantMapper: TypographyType = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  p: 'p',
  span: 'span',
  b: 'b'
};

const elipsis = () => {
  return `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  `;
};

export const StyledTypography = styled.span<Props>`
  ${(props) => props.variant && stylesWrapper[props.variant]};

  text-align: ${({ textAlign }) => textAlign};
  text-transform: ${({ textTransform }) => textTransform};
  font-style: ${({ fontStyle }) => fontStyle};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${(props) => `${props.fontSize}px` ?? ''};
  ${({ color }) => color && colorMapper[color]};
  ${({ noWrap }) => noWrap && noWrapStyles};

  ${({ styles }) => styles && styles};
  margin-bottom: ${(props) => props.marginBottom && `${props.marginBottom}px`};
  margin: ${(props) => props.margin && `${props.margin}`};
  ${(props) => props.elipsis && elipsis()}
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode | React.ReactNode[];
  fontWeight?: 'regular' | 'bold';
  color?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'textPrimary'
    | 'textMenu'
    | 'textSecondary'
    | 'textTertiary'
    | 'greyedOut'
    | 'error'
    | 'warning'
    | 'success';
  variant?: TypographyVariant;
  textAlign?: 'left' | 'center' | 'right' | 'justify';
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none' | 'initial' | 'inherit';
  fontStyle?: 'normal' | 'italic' | 'oblique';
  noWrap?: boolean;
  component?: React.ElementType;
  styles?: { root?: string };
  marginBottom?: number;
  margin?: string;
  fontSize?: number;
  elipsis?: boolean;
}

const Typography = (props: Props) => {
  const {
    children,
    fontWeight,
    color,
    textAlign,
    variant = 'p',
    component,
    noWrap,
    textTransform,
    styles,
    marginBottom,
    margin,
    ...rest
  } = props;

  const tag = component || variantMapper[variant] || 'span';

  return (
    <StyledTypography
      as={tag}
      fontWeight={fontWeight}
      color={color}
      textAlign={textAlign}
      variant={variant}
      noWrap={noWrap}
      textTransform={textTransform}
      styles={styles?.root}
      marginBottom={marginBottom}
      margin={margin}
      {...rest}
    >
      {children}
    </StyledTypography>
  );
};

export default Typography;
