import { useCallback } from 'react';
import LoadingOverlay from '../../../../../components/loading-overlay/LoadingOverlay';
import { GOODS_ROUTE } from '../../../../../utils/constants';
import addQueryParams from '../../../../../utils/routing/add-query-params';
import { isDevice } from '../../../../../utils/device-handling/deviceDetectionUtils';
import { useSelector } from 'react-redux';
import { useNavigate } from '../../../../../hooks/useNavigate';
import DialogView from '../../../../../components/dialog-view/DialogView';
import styled from 'styled-components';
import { DialogContent } from '../../../components/Styles';
import OrderReceiptContent from './OrderReceiptContent';
import { selectSelectedOrderReceipt } from '../../../../../stateManagement/reducers/orderGoodsReducer';
import StatusTag from '../../../../../components/status-tag/StatusTag';
import { OrderStatus } from '../../../../../api/api';
import NavigationHeader from '../../../components/NavigationHeader';
import { getEnumDisplayValue } from '../../../../../utils/enumUtils';

interface Props {
  onClose?: () => void;
}

const OrderReceiptView = (props: Props) => {
  const { onClose } = props;
  const order = useSelector(selectSelectedOrderReceipt);
  const fullscreenTask = isDevice();
  const navigate = useNavigate();

  const handleCloseDialog = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const handleCloseReceipt = useCallback(() => {
    navigate(addQueryParams('/' + GOODS_ROUTE));
  }, [navigate]);

  const Header = () => {
    return (
      <NavigationHeader
        headerTitle={'Bestilling: ' + order?.transferId}
        onClose={fullscreenTask ? handleCloseReceipt : handleCloseDialog}
        subHeaderComponent={
          <StatusTag orderStatus={order?.orderStatus as OrderStatus}>
            {getEnumDisplayValue(order?.orderStatus as OrderStatus)}
          </StatusTag>
        }
      />
    );
  };

  return (
    <>
      {!order ? (
        <LoadingOverlay />
      ) : (
        <>
          {fullscreenTask ? (
            <FullScreenContainer>
              {Header()}
              <OrderReceiptContent order={order} />
            </FullScreenContainer>
          ) : (
            <DialogView handleClose={handleCloseDialog}>
              <StyledDialogContent>
                {Header()}
                <OrderReceiptContent order={order} />
              </StyledDialogContent>
            </DialogView>
          )}
        </>
      )}
    </>
  );
};

const FullScreenContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.palette.background.primary};
  overflow-y: hidden;
`;

const StyledDialogContent = styled(DialogContent)`
  width: 80vw;
  height: 80vh;
  padding: 0;
  background-color: ${(props) => props.theme.palette.background.primary};
`;

export default OrderReceiptView;
