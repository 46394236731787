import { MouseEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { getSelectedTaskId } from '../../../../../stateManagement/reducers/workPlanViewReducer';
import { DialogBody } from '../../../../../stateManagement/reducers/confirmDialogReducer';

import { WorkTaskStatus, WorkPlanItemDTO, BaseWorkTaskTypeEnum, MultiDayStatusEnum } from '../../../../../api/api';
import { getLocationString } from '../../../../../utils/location/locationHandling';
import { formatDateHourString, getFullDateString } from '../../../../../utils/dateHandling';
import Button from '../../../../../components/button/Button';
import { NavigateIcon } from '../../../../../assets/icons/NavigateIcon';
import { PlayIconOutline } from '../../../../../assets/icons/PlayIconOutline';
import NotificationService from '../../../../../services/NotificationService';
import { useConfirmationDialog } from '../../../../../hooks/useConfirmationDialog';
import TaskService from '../../../../../services/TaskService';
import { getEnumDisplayValue } from '../../../../../utils/enumUtils';
import { VehicleDriveIcon } from '../../../../../assets/icons/VehicleDriveIcon';
import StatusTag from '../../../../../components/status-tag/StatusTag';
import { useUpdateTaskStateDialog } from '../../../../../hooks/useUpdateTaskStateDialog';
import { log } from '../../../../../utils/logging/log';
import Typography from '../../../../../components/typography/Typography';
import { ClockIcon } from '../../../../../assets/icons/ClockIcon';
import InternalTimeRegForm from '../../../../../blocks/internal-timereg-form/InternalTimeRegForm';
import { WorkPlanItemDTOExtended } from '../../../../../models/WorkPlanItem';
import { StopIcon } from '../../../../../assets/icons/StopIcon';
import { useNavigateToTask } from '../../../../../hooks/useNavigateToTask';
import { EyeIcon } from '../../../../../assets/icons/EyeIcon';

interface Props {
  task: WorkPlanItemDTOExtended;
  handleSelect: (taskId: number | undefined) => void;
  enableActions: boolean;
  fetchTasks: () => void;
  isUnfinished?: boolean;
  isCalendar?: boolean;
}

const DayOverviewTask = (props: Props) => {
  const { task, handleSelect, enableActions, fetchTasks, isUnfinished = false, isCalendar = false } = props;
  const [taskStatus, setTaskStatus] = useState<WorkTaskStatus>(task.status as WorkTaskStatus);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [openInternalTimeRegistrationForm, setOpenInternalTimeRegistrationForm] = useState(false);

  const expandedTaskId = useSelector(getSelectedTaskId);

  const selected = useMemo(() => {
    return expandedTaskId === task.workTaskId;
  }, [expandedTaskId, task.workTaskId]);

  const navigateToTask = useNavigateToTask();
  const { getTaskStatusUpdateDialog } = useUpdateTaskStateDialog();
  const { getConfirmation } = useConfirmationDialog();
  const ref = useRef<HTMLInputElement>(null);

  const handleOnClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (task.baseWorkTaskType === BaseWorkTaskTypeEnum.Absence) return;
    handleSelect(task.workTaskId);
  };

  const handleOpenTask = useCallback(
    (e?: any) => {
      e && e.stopPropagation();
      if (!task.workTaskId || !task.baseWorkTaskType) return;
      navigateToTask(task.workTaskId, task.baseWorkTaskType);
    },
    [task.workTaskId, task.baseWorkTaskType, navigateToTask]
  );

  const statusUpdatedSuccessCallback = useCallback(
    (newStatus: WorkTaskStatus) => {
      NotificationService.success(`"${getEnumDisplayValue(newStatus)}"`);
      setTaskStatus(newStatus);
      setUpdatingStatus(false);

      if (
        (task.baseWorkTaskType === BaseWorkTaskTypeEnum.WorkTask ||
          task.baseWorkTaskType === BaseWorkTaskTypeEnum.SubWorkTask) &&
        newStatus === WorkTaskStatus.Ongoing
      ) {
        handleOpenTask();
      }
    },
    [handleOpenTask, task.baseWorkTaskType]
  );

  const updateStatus = useCallback(
    (id: number, workTaskStatus: WorkTaskStatus, flsId?: number, successCallback?: (newStatus: WorkTaskStatus) => void) => {
      setUpdatingStatus(true);
      TaskService.setWorkPlanTaskStatus({
        workTaskId: id,
        status: workTaskStatus,
        flsId: flsId ?? -1
      })
        .then((res) => {
          successCallback && successCallback(res.status);
        })
        .catch((error) => {
          log(error);
          setUpdatingStatus(false);
        });
    },
    []
  );

  const handleUpdateStatus = useCallback(
    async (e?: any) => {
      e && e.stopPropagation();
      if (!task.workTaskId) {
        return;
      }

      if (taskStatus === WorkTaskStatus.Completed) {
        NotificationService.error(
          `Kan ikke starte opgaven, da den er "${getEnumDisplayValue(WorkTaskStatus['Completed'])}"`
        );
        return;
      }

      if (isUnfinished || taskStatus === WorkTaskStatus.Ongoing) {
        if (task.workTaskId && task.baseWorkTaskType && task.taskType?.id) {
          await getTaskStatusUpdateDialog(
            task.workTaskId,
            task.baseWorkTaskType,
            task.taskType?.id,
            task.flsId ?? -1,
            task.multiDayStatus === MultiDayStatusEnum.MultiDay,
            taskStatus
          );
          fetchTasks();
        }
      } else if (taskStatus === WorkTaskStatus.Planned || taskStatus === WorkTaskStatus.PlannedAppointment) {
        updateStatus(task.workTaskId, WorkTaskStatus.OnRoute, task.flsId, (newStatus) =>
          statusUpdatedSuccessCallback(newStatus)
        );
      } else {
        updateStatus(task.workTaskId, WorkTaskStatus.Ongoing, task.flsId, (newStatus) =>
          statusUpdatedSuccessCallback(newStatus)
        );
      }
    },
    [
      task.workTaskId,
      task.baseWorkTaskType,
      task.taskType?.id,
      task.flsId,
      task.multiDayStatus,
      taskStatus,
      isUnfinished,
      getTaskStatusUpdateDialog,
      fetchTasks,
      updateStatus,
      statusUpdatedSuccessCallback
    ]
  );

  const handleNavigate = async (e?: any) => {
    e && e.stopPropagation();
    const latLngUrl =
      'https://www.google.com/maps/dir/?api=1&destination=' +
      task.tasklocation?.latitude +
      ',' +
      task.tasklocation?.longitude;
    window.open(latLngUrl, '_top');
    let statusUpdated = false;

    if (task.workTaskId && (taskStatus === WorkTaskStatus.Planned || taskStatus === WorkTaskStatus.PlannedAppointment)) {
      statusUpdated = true;
      updateStatus(task.workTaskId, WorkTaskStatus.OnRoute, task.flsId, () =>
        statusUpdatedSuccessCallback(WorkTaskStatus.OnRoute)
      );
    }

    const confirmation = await getConfirmation(navigateDialogBody);
    if (confirmation === 'confirm') {
      if (task.workTaskId && (statusUpdated || taskStatus === WorkTaskStatus.OnRoute)) {
        updateStatus(task.workTaskId, WorkTaskStatus.Ongoing, task.flsId, () =>
          statusUpdatedSuccessCallback(WorkTaskStatus.Ongoing)
        );
      } else if (task.baseWorkTaskType !== BaseWorkTaskTypeEnum.InternalWorkTask) {
        handleOpenTask();
      }
    }
  };

  const actionButton = useCallback(() => {
    let content = <></>;

    if (isUnfinished) {
      content = (
        <>
          <StopIcon size="16px" />
          Afslut opgave
        </>
      );
    } else {
      switch (taskStatus) {
        case WorkTaskStatus.Planned:
        case WorkTaskStatus.PlannedAppointment:
          content = (
            <>
              <VehicleDriveIcon size="30px" />
              Kør
            </>
          );
          break;
        case WorkTaskStatus.Ongoing:
          content = (
            <>
              <StopIcon size="16px" />
              Stop opgave
            </>
          );
          break;
        default:
          content = (
            <>
              <PlayIconOutline size="28px" />
              Start opgave
            </>
          );
          break;
      }
    }

    return (
      <Button
        variant="primary"
        onClick={(e) => handleUpdateStatus(e)}
        disabled={
          !enableActions ||
          task.status === WorkTaskStatus.Completed ||
          task.status === WorkTaskStatus.Pause ||
          task.status === WorkTaskStatus.Processed
        }
        data-testid="workplan-overview-buttons"
        isLoading={updatingStatus}
      >
        {content}
      </Button>
    );
  }, [taskStatus, isUnfinished, task.status, enableActions, updatingStatus, handleUpdateStatus]);

  const renderTextContent = (task: WorkPlanItemDTO) => {
    return (
      <TextContent>
        <Row>
          {selected && !isUnfinished && (
            <TextItem>
              <b>Adresse</b>
            </TextItem>
          )}
          <TextItem flex={selected && !isUnfinished ? '3' : '1'} title={getLocationString(task?.tasklocation)}>
            {getLocationString(task?.tasklocation)}
          </TextItem>
        </Row>
        {selected && !isUnfinished && (
          <>
            {task.concurrentTasks?.map(
              (c, index) =>
                c.taskType && (
                  <Row key={index}>
                    <TextItem flex="1">
                      <b>{getEnumDisplayValue(c.taskType)}:</b>
                    </TextItem>
                    <TextItem flex="3">{c.assignedTo}</TextItem>
                  </Row>
                )
            )}
          </>
        )}
        {!isUnfinished && task.notesForTechnician && (
          <Row>
            <TextItem>
              <b>Note til tekniker</b>
            </TextItem>
            <TextItem flex={selected && !isUnfinished ? '3' : '1'}>{task.notesForTechnician}</TextItem>
          </Row>
        )}
      </TextContent>
    );
  };

  if (task.plannedArrival === undefined) {
    return <></>;
  }

  return (
    <>
      <InternalTimeRegForm
        taskId={task.workTaskId}
        open={openInternalTimeRegistrationForm}
        handleReturn={() => setOpenInternalTimeRegistrationForm(false)}
        taskType={task.taskType}
      />

      <Container
        workTaskType={task.baseWorkTaskType}
        selected={selected}
        isUnfinished={isUnfinished}
        isCompleeted={
          task.status === WorkTaskStatus.Completed ||
          task.status === WorkTaskStatus.Pause ||
          task.status === WorkTaskStatus.Processed
        }
        onClick={handleOnClick}
      >
        <StyledHeader>
          <TextContainer>
            {task.plannedArrival && isUnfinished && (
              <Row>
                <Typography variant="p" color="textMenu">
                  {getFullDateString(new Date(task.plannedArrival))}
                </Typography>
              </Row>
            )}
            <Typography variant={selected && !isUnfinished ? 'h4' : 'b'}>
              {task.baseWorkTaskType === BaseWorkTaskTypeEnum.Absence ? (
                task.notes
              ) : (
                <>
                  {task.taskType?.name} - {task?.workTaskId}
                </>
              )}
            </Typography>
          </TextContainer>
          <StatusContainer>
            {task.baseWorkTaskType !== BaseWorkTaskTypeEnum.Absence && (
              <StyledStatusTag workTaskStatus={taskStatus ? taskStatus : undefined}>
                {taskStatus && getEnumDisplayValue(taskStatus)}
              </StyledStatusTag>
            )}
            {!isUnfinished && (
              <SelectedTime>
                <Typography variant="h4">
                  {task.appointmentTimeStart && task.appointmentTimeEnd
                    ? `${formatDateHourString(task.appointmentTimeStart)}\xa0- ${formatDateHourString(
                        task.appointmentTimeEnd
                      )}`
                    : ''}
                </Typography>
              </SelectedTime>
            )}
          </StatusContainer>
        </StyledHeader>

        <Row>{renderTextContent(task)}</Row>

        {(selected || isUnfinished) && task.baseWorkTaskType !== BaseWorkTaskTypeEnum.Absence && (
          <Column moveRight={isUnfinished || isCalendar}>
            <StyledbuttonContainer ref={ref}>
              {!isCalendar && actionButton()}
              {task.baseWorkTaskType === BaseWorkTaskTypeEnum.InternalWorkTask ? (
                <Button variant="secondary" onClick={() => setOpenInternalTimeRegistrationForm(true)}>
                  <ClockIcon size="18px" />
                  Registrer intern tid
                </Button>
              ) : (
                <Button data-testid="open-task-button" variant="secondary" onClick={(e) => handleOpenTask(e)}>
                  {ref?.current?.offsetWidth && ref.current.offsetWidth > 525 && <EyeIcon size="18px" />}
                  Åbn opgave
                </Button>
              )}
              {!isUnfinished && !isCalendar && (
                <Button variant="secondary" onClick={(e) => handleNavigate(e)} disabled={!enableActions}>
                  {ref?.current?.offsetWidth && ref.current.offsetWidth > 525 && <NavigateIcon size="18px" />}
                  Find vej
                </Button>
              )}
            </StyledbuttonContainer>
          </Column>
        )}
      </Container>
    </>
  );
};

const navigateDialogBody: DialogBody = {
  headerText: 'Ankommet?',
  bodyText: 'Er du ankommet til destinationen?',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

const Container = styled.div<{
  selected?: boolean;
  workTaskType: BaseWorkTaskTypeEnum | undefined;
  isUnfinished?: boolean;
  isCompleeted?: boolean;
}>`
  background: ${(props) =>
    props.workTaskType === BaseWorkTaskTypeEnum.InternalWorkTask
      ? props.theme.palette.functions.warning.tertiary
      : props.isCompleeted && !props.selected && props.isUnfinished
      ? props.theme.palette.grey.black5
      : props.theme.palette.background.primary};

  background: ${(props) =>
    props.workTaskType === BaseWorkTaskTypeEnum.Absence && props.theme.palette.functions.action.tertiary};

  margin: 12px;
  padding: 16px 24px 16px 32px;

  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.15);
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  transition: margin 250ms;

  ${({ selected, isUnfinished }) =>
    selected &&
    !isUnfinished &&
    css`
      padding: 32px 24px 32px 32px;
      margin: 24px 12px !important;
    `};
`;

const StyledHeader = styled.div`
  text-align: start;
  display: flex;
  justify-content: space-between;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  column-gap: 12px;
  text-align: start;
`;

const TextItem = styled.div<{ flex?: string }>`
  ${(props) => ({ ...props.theme.typography.p })};
  flex: ${(props) => props.flex};
  text-align: start;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const Column = styled.div<{ moveRight?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;

  column-gap: 8px;
  row-gap: 8px;

  ${({ moveRight }) =>
    moveRight &&
    css`
      margin-top: 0px;
      flex-direction: column;
      align-items: end;
    `};
`;

const StyledbuttonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  column-gap: 8px;
  row-gap: 8px;
`;

const SelectedTime = styled.span`
  display: inline-flex;
  margin-left: 12px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StatusContainer = styled.div<{ selected?: boolean }>`
  text-align: end;

  ${({ selected }) =>
    selected &&
    css`
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      align-items: center;
    `};
`;

const StyledStatusTag = styled(StatusTag)`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
`;

export default DayOverviewTask;
