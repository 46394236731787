import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { SelectedGoods } from '../../models/Goods';

interface CountGoodsState {
  goods: SelectedGoods[];
  lastUpdated?: Date;
}

const initialState: CountGoodsState = {
  goods: [],
  lastUpdated: undefined
};

const countGoodsSlice = createSlice({
  name: 'countGoods',
  initialState: initialState,
  reducers: {
    setInitialGoods: (state: CountGoodsState, action: PayloadAction<SelectedGoods[]>) => {
      state.goods = action.payload;
      state.lastUpdated = undefined;
    },
    setGoods: (state: CountGoodsState, action: PayloadAction<SelectedGoods[]>) => {
      state.goods = action.payload;
      state.lastUpdated = new Date();
    },
    resetLastUpdated: (state: CountGoodsState) => {
      state.lastUpdated = undefined;
    }
  }
});

export const { setGoods, setInitialGoods, resetLastUpdated } = countGoodsSlice.actions;
export const selectGoods = (state: RootState) => state.countGoodsReducer.goods;
export const selectGoodsLastUpdated = (state: RootState) => state.countGoodsReducer.lastUpdated;

export default countGoodsSlice.reducer;
