import styled from 'styled-components';
import IconButton from '../icon-button/IconButton';
import { ChevronLeft } from '../../assets/icons/ChevronLeft';
import Typography from '../typography/Typography';

interface Props {
  title?: string;
  handleReturn?: () => void;
  subHeaderComponent?: JSX.Element;
}

const DialogViewPageHeader = (props: Props) => {
  const { handleReturn, title, subHeaderComponent } = props;

  return (
    <HeaderContainer>
      {handleReturn && (
        <IconButton onClick={handleReturn} padding={'8px'}>
          <ChevronLeft size="18px" />
        </IconButton>
      )}
      <StyledTypography variant="h5" fontWeight="bold" onClick={handleReturn}>
        {title}
      </StyledTypography>
      {subHeaderComponent}
    </HeaderContainer>
  );
};
const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${(props) => props.theme.spacing(5)};
  margin: ${(props) => props.theme.spacing(6)} 0;
`;

const StyledTypography = styled(Typography)`
  cursor: ${(props) => (props.onClick ? 'pointer' : '')};
`;

export default DialogViewPageHeader;
