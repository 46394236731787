import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';

import IconButton from '../../../../components/icon-button/IconButton';
import useTableInstance from '../../../../hooks/useTableInstance';
import { GoodsData, ProductDetails } from '../../../../models/Goods';
import { selectAutocompleteFn, SelectAutocompleteFn, SearchColumnFilter } from '../../../../blocks/table/TableFilterUtils';
import { ShopAddIcon } from '../../../../assets/icons/ShopAddIcon';
import n1LightTheme from '../../../../theme/light-theme';

export const useGoodsList = (goods: ProductDetails[], selectGoods: (goodsId?: string, amount?: number) => void) => {
  const data = useMemo((): GoodsData[] => {
    if (!goods.length) return [];

    return goods.map((good) => {
      return {
        goodsId: good.itemId ? good.itemId : '',
        goodsName: good.name ?? '-',
        searchName: good.searchName ?? '-',
        goodsGroupName:
          good.itemGroupName && good.itemGroupId
            ? good.itemGroupName + ` (${good.itemGroupId})`
            : good.itemGroupName ?? '' + good.itemGroupId ?? ''
      } as GoodsData;
    });
  }, [goods]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Varenummer',
        accessor: 'goodsId',
        minWidth: 60,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Varenavn',
        accessor: 'goodsName',
        minWidth: 100,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Gruppenavn',
        accessor: 'goodsGroupName',
        minWidth: 75,
        Filter: SelectAutocompleteFn,
        filter: selectAutocompleteFn
      },
      {
        Header: 'Søgt navn',
        accessor: 'searchName',
        minWidth: 50,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: '',
        accessor: 'actions',
        minWidth: 50,
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps: CellProps<GoodsData>) => {
          const { cell } = cellProps;
          return (
            <IconButton onClick={() => selectGoods(cell.row.original.goodsId, 1)}>
              <ShopAddIcon size="24px" color={n1LightTheme.palette.text.primary} />
            </IconButton>
          );
        }
      }
    ] as Column<GoodsData>[];
  }, [selectGoods]);

  const tableInstance = useTableInstance<GoodsData>(data, columns, {
    initialState: { pageSize: 50, pageIndex: 0 }
  });

  return { tableInstance };
};
