import styled from 'styled-components';
import { WorkTaskStatus, OrderStatus } from '../../api/api';

interface Props {
  workTaskStatus?: WorkTaskStatus;
  orderStatus?: OrderStatus;
  statusValue?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

const StatusTag = (props: Props) => {
  const { children, workTaskStatus, orderStatus, statusValue, onClick, ...rest } = props;
  const purple = '#8266E6';
  const blue = '#557FFE';
  const orange_light = '#FFBB89';
  const orange_dark = '#FF7A19';
  const yellow = '#FFEE52';
  const green_light = '#79C240';
  const red = '#DC4040';
  const green_dark = '#60935D';
  const grey = '#C7C7C7';

  let color = '#a3a3a3'; //light grey
  switch (workTaskStatus) {
    case undefined:
      break;
    case WorkTaskStatus.Created:
      color = purple;
      break;
    case WorkTaskStatus.ReadyForPlanning:
      color = blue;
      break;
    case WorkTaskStatus.Planned:
      color = orange_light;
      break;
    case WorkTaskStatus.PlannedAppointment:
      color = orange_dark;
      break;
    case WorkTaskStatus.OnRoute:
      color = yellow;
      break;
    case WorkTaskStatus.Ongoing:
      color = green_light;
      break;
    case WorkTaskStatus.Pause:
      color = red;
      break;
    case WorkTaskStatus.Completed:
      color = green_dark;
      break;
    case WorkTaskStatus.Processed:
      color = grey;
      break;
  }

  switch (orderStatus) {
    case undefined:
      break;
    case OrderStatus.Pending:
      color = purple;
      break;
    case OrderStatus.Created:
      color = blue;
      break;
    case OrderStatus.Shipped:
      color = yellow;
      break;

    case OrderStatus.Delivered:
      color = green_dark;
      break;
    case OrderStatus.Received:
      color = grey;
      break;
  }

  switch (statusValue?.toLowerCase()) {
    case undefined:
      break;
    case 'oprettet':
      color = purple;
      break;
    case 'klar til planlægning':
      color = blue;
      break;
    case 'planlagt':
      color = orange_light;
      break;
    case 'planlagt (aftale)':
      color = orange_dark;
      break;
    case 'på rute':
      color = yellow;
      break;
    case 'igangværende':
      color = green_light;
      break;
    case 'pause':
      color = red;
      break;
    case 'udført':
      color = green_dark;
      break;
    case 'afsluttet':
      color = grey;
      break;
  }

  return (
    <Container onClick={onClick} {...rest}>
      <ColorTile color={color} />
      {children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  column-gap: 8px;
`;

const ColorTile = styled.div<{ color: string }>`
  width: 14px;
  height: 14px;
  border-radius: 3px;
  background: ${(props) => props.color};
`;

export default StatusTag;
