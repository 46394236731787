import { useCallback } from 'react';
import styled from 'styled-components';

import { BaseWorkTaskTypeEnum, SubWorkTaskDTO, WorkTaskStatus } from '../../../../../api/api';

import Link from '../../../../../components/link/Link';
import StatusTag from '../../../../../components/status-tag/StatusTag';
import Typography from '../../../../../components/typography/Typography';
import { RowBase, RowLabel, RowValue } from '../../components/TextRow';

import { getEnumDisplayValue } from '../../../../../utils/enumUtils';
import { useNavigateToTask } from '../../../../../hooks/useNavigateToTask';

const RELATIONTREE_MAX_DEPTH = 15;

export enum TaskRelationType {
  RELATIONALTASK = 'RELATIONALTASK',
  MAINTASK = 'MAINTASK',
  SUBTASK = 'SUBTASK'
}

interface Props {
  taskId?: number;
  taskStatus?: WorkTaskStatus;
  assignedTechnician?: string;
  index: number;
  taskRelationType: TaskRelationType;
  relationDescription: string;
  isSubTask?: boolean;
  releaseWorkTask: () => void;
  level?: number;
  activeTask?: boolean;
}

export const TaskDetailsStepRelatedTask = (props: Props) => {
  const {
    taskId,
    taskStatus,
    assignedTechnician,
    index,
    isSubTask = false,
    taskRelationType,
    relationDescription,
    releaseWorkTask,
    level = 0,
    activeTask
  } = props;

  const navigateToTask = useNavigateToTask();

  const handleLinkClick = useCallback(
    (subtaskId: SubWorkTaskDTO['id']) => {
      if (!subtaskId) return;
      releaseWorkTask();
      navigateToTask(subtaskId, isSubTask ? BaseWorkTaskTypeEnum.SubWorkTask : BaseWorkTaskTypeEnum.WorkTask);
    },
    [releaseWorkTask, navigateToTask, isSubTask]
  );

  return (
    <StyledRowBase key={taskId} level={level < RELATIONTREE_MAX_DEPTH ? level : RELATIONTREE_MAX_DEPTH}>
      <StyledRowLabel>
        {index === 0 || taskRelationType === TaskRelationType.RELATIONALTASK ? (
          <Typography fontStyle={activeTask ? 'oblique' : 'normal'}>
            {level < RELATIONTREE_MAX_DEPTH ? '' : level + '. '}
            {relationDescription}
          </Typography>
        ) : (
          ''
        )}
      </StyledRowLabel>
      <StyledRowValue>
        <Link onClick={() => !activeTask && handleLinkClick(taskId)} minWidth="80px" disabled={activeTask}>
          <Typography
            fontSize={16}
            color={activeTask ? 'greyedOut' : 'inherit'}
            fontStyle={activeTask ? 'oblique' : 'normal'}
          >
            {taskId}
          </Typography>
        </Link>
        <StyledSpacing fontSize={16} component="span" color={activeTask ? 'greyedOut' : 'inherit'}>
          -
        </StyledSpacing>
        <Typography
          fontStyle={assignedTechnician && !activeTask ? 'normal' : 'italic'}
          color={activeTask ? 'greyedOut' : 'inherit'}
          fontSize={16}
          component="span"
        >
          {assignedTechnician ? assignedTechnician : 'Ingen person tilknyttet'}
        </Typography>
      </StyledRowValue>
      <StatusTagContainer>
        <StatusTag workTaskStatus={taskStatus}>
          {taskStatus && (
            <Typography color={activeTask ? 'greyedOut' : 'inherit'}>
              {getEnumDisplayValue(WorkTaskStatus[taskStatus])}
            </Typography>
          )}
        </StatusTag>
      </StatusTagContainer>
    </StyledRowBase>
  );
};

const StyledRowBase = styled(RowBase)<{ level: number }>`
  align-items: center;
  padding-left: ${({ level }) => `${level * 25}px`};
`;

const StyledRowValue = styled(RowValue)`
  display: flex;
  align-items: center;
`;

const StyledSpacing = styled(Typography)`
  margin-right: 5px;
`;

const StatusTagContainer = styled.div`
  flex: 3;
`;

const StyledRowLabel = styled(RowLabel)`
  overflow-wrap: anywhere;
`;
