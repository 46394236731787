import { useCallback } from 'react';
import DialogView from '../../../components/dialog-view/DialogView';
import { DialogContent } from '../components/Styles';
import styled from 'styled-components';
import { isDevice } from '../../../utils/device-handling/deviceDetectionUtils';
import CountGoodsViewContent from './CountGoodsViewContent';
import { useNavigate } from '../../../hooks/useNavigate';

interface Props {
  onClose?: () => void;
}

const CountGoodsView = (props: Props) => {
  const { onClose } = props;

  const fullscreenTask = isDevice();

  const navigate = useNavigate();

  const handleCloseDialog = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const handleCloseTask = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      {fullscreenTask ? (
        <FullScreenContainer>
          <CountGoodsViewContent onClose={handleCloseTask} />
        </FullScreenContainer>
      ) : (
        <DialogView handleClose={handleCloseDialog}>
          <StyledDialogContent>
            <CountGoodsViewContent onClose={handleCloseDialog} />
          </StyledDialogContent>
        </DialogView>
      )}
    </>
  );
};

const FullScreenContainer = styled.div`
  height: 100vh;
  width: 100vw;
`;

const StyledDialogContent = styled(DialogContent)`
  width: 70vw;
  height: 80vh;
`;

export default CountGoodsView;
